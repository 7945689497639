import React, { useCallback, useRef, useEffect, useState } from 'react';
import ReactFlow, {
  Background,
  Controls,
  addEdge,
  useNodesState,
  useEdgesState,
  useReactFlow,
  Position,
} from 'reactflow';
import 'reactflow/dist/style.css';
import MindMapNode from './MindMapNode';
import useLayoutedElements from '../hooks/useLayoutedElements';
import useReactFlowFileStore from '../diagramStore'; // Zustand store
import DownloadButton from './DownloadButton';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const nodeTypes = {
  mindmap: MindMapNode, // Only MindMapNode
};

const CustomControls = ({ getLayoutedElements, setInteractivity }) => {
  const { zoomIn, zoomOut, fitView } = useReactFlow();
  const [isInteractive, setIsInteractive] = useState(true); // Tracks interaction mode

  const toggleInteractiveMode = () => {
    setIsInteractive((prev) => {
      const newMode = !prev;
      setInteractivity(newMode); // Update interactivity state in parent
      return newMode;
    });
  };

  const buttonStyle = {
    backgroundColor: 'white',
    border: '0px solid #ccc',
    borderRadius: '4px',
    padding: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease, transform 0.2s ease',
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '10px',
        position: 'absolute',
        bottom: '10px',
        left: '10px',
        padding: '10px',
        backgroundColor: '#ffffff',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        zIndex: 999,
      }}
    >
      {/* Zoom In Button */}
      <button
        onClick={zoomIn}
        style={buttonStyle}
        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f0f0f0')}
        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'white')}
        data-tooltip-id="zoom-in"
        data-tooltip-content="Zoom In"
      >
        <img src="/plus-icon.png" alt="Zoom In" style={{ width: '16px', height: '16px' }} />
      </button>
      <Tooltip id="zoom-in" />

      {/* Zoom Out Button */}
      <button
        onClick={zoomOut}
        style={buttonStyle}
        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f0f0f0')}
        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'white')}
        data-tooltip-id="zoom-out"
        data-tooltip-content="Zoom Out"
      >
        <img src="/minus-icon.png" alt="Zoom Out" style={{ width: '16px', height: '16px' }} />
      </button>
      <Tooltip id="zoom-out" />

      {/* Fit View Button */}
      <button
        onClick={fitView}
        style={buttonStyle}
        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f0f0f0')}
        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'white')}
        data-tooltip-id="fit-view"
        data-tooltip-content="Fit View"
      >
        <img src="/icons8-capture-96.png" alt="Fit View" style={{ width: '16px', height: '16px' }} />
      </button>
      <Tooltip id="fit-view" />

      {/* Lock (Interactive Mode) Button */}
      <button
        onClick={toggleInteractiveMode}
        style={buttonStyle}
        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f0f0f0')}
        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'white')}
        data-tooltip-id="interactive"
        data-tooltip-content={isInteractive ? 'Disable Interaction' : 'Enable Interaction'}
      >
        <img
          src={isInteractive ? '/icons8-unlock-90.png' : '/icons8-lock-90.png'}
          alt={isInteractive ? 'Unlock' : 'Lock'}
          style={{ width: '16px', height: '16px' }}
        />
      </button>
      <Tooltip id="interactive" />


      {/* Horizontal Layout Button */}
      <button
        onClick={() => getLayoutedElements({ 'elk.algorithm': 'layered', 'elk.direction': 'RIGHT' })}
        style={buttonStyle}
        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f0f0f0')}
        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'white')}
        data-tooltip-id="horizontal-layout"
        data-tooltip-content="Horizontal Layout"
      >
        <img
          src="/icons8-grip-horizontal-96.png"
          alt="Horizontal Layout"
          style={{ width: '16px', height: '16px' }}
        />
      </button>
      <Tooltip id="horizontal-layout" />
      <DownloadButton />
    </div>
  );
};

const ReactFlowChart = ({ initialJson, fileName }) => {
  const addOrUpdateFile = useReactFlowFileStore((state) => state.addOrUpdateFile);
  const getFile = useReactFlowFileStore((state) => state.getFile);
  const saveFile = useReactFlowFileStore((state) => state.saveFile);
  const connectingNodeId = useRef(null);
  const reactFlowInstance = useReactFlow();
  const [parsedJson, setParsedJson] = useState(null);
  const { getLayoutedElements } = useLayoutedElements();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const isMounted = useRef(false); // Flag to track initial mounting
  const saveTimerRef = useRef(null); // Ref to track the timer
  const [isInteractive, setIsInteractive] = useState(true); // Manage interactivity
  const connectingHandlePosition = useRef(null);

  // Function to handle saveFile and reset the timer
  const startSaveTimer = useCallback(() => {
    if (saveTimerRef.current) {
      clearTimeout(saveTimerRef.current); // Reset timer on any new change
    }

    saveTimerRef.current = setTimeout(() => {
      if (isMounted.current) {
        //console.log('SaveFile triggered after 1 seconds:', new Date().toLocaleTimeString());
        saveFile(fileName);
      }
    }, 1000); // 1 seconds timer
  }, [fileName, saveFile]);

  // Set isMounted to true after component mounts
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Parse the initial JSON and load nodes and edges from the store
  useEffect(() => {
    try {
      const parsed = typeof initialJson === 'string' ? JSON.parse(initialJson) : initialJson;
      setParsedJson(parsed);
      if (parsed && fileName) {
        addOrUpdateFile(fileName, JSON.stringify(parsed));
      }
    } catch (error) {
      console.error('Error parsing JSON:', error);
      setParsedJson(null);
    }
  }, [initialJson, fileName, addOrUpdateFile]);

  // Load nodes and edges from the file store without triggering save
  useEffect(() => {
    if (fileName) {
      const diagram = getFile(fileName);
      if (diagram) {
        const parsed = JSON.parse(diagram);
        setNodes(parsed.nodes || []);
        setEdges(parsed.edges || []);
      } else {
        console.warn('No diagram found in store.');
      }
    }
  }, [fileName, getFile]);

  useEffect(() => {
    if (!isMounted.current) return; // Ensure this runs only after mounting
    if (nodes.length > 0 && edges.length > 0) {
      getLayoutedElements(); // Apply layout
    }
  }, [getLayoutedElements]);


  // Remember which node started the connection
  const onConnectStart = useCallback((_, { nodeId }) => {
    connectingNodeId.current = nodeId;
  }, []);

  const getChildNodePosition = (event, parentNode) => {
    const panePosition = reactFlowInstance.project({
      x: event.clientX,
      y: event.clientY,
    });
  
    // Calculate if new node should be on the left or right based on mouse position
    const isLeftSide = panePosition.x < parentNode.position.x;
    
    return {
      x: isLeftSide ? panePosition.x - 150 : panePosition.x + 150,
      y: panePosition.y
    };
  };

  const onConnectEnd = useCallback(
    (event) => {
      const targetIsPane = event.target.classList.contains('react-flow__pane');
  
      if (targetIsPane && connectingNodeId.current) {
        const parentNode = nodes.find((node) => node.id === connectingNodeId.current);
        const childNodePosition = getChildNodePosition(event, parentNode);
  
        if (parentNode) {
          const newNodeId = `node_${Date.now()}`;
          // Determine if new node is on the left or right of parent
          const isLeftSide = childNodePosition.x < parentNode.position.x;
  
          const newNode = {
            id: newNodeId,
            type: 'mindmap',
            position: childNodePosition,
            data: {
              label: '',
              ...Object.fromEntries(
                Object.entries(parentNode.data).filter(([key]) => key !== 'label')
              ),
              // Hide appropriate handles based on position
              hideLeft: !isLeftSide,
              hideRight: isLeftSide
            },
            style: {
              ...parentNode.style,
            },
          };
  
          const newEdge = {
            id: `edge_${Date.now()}`,
            source: isLeftSide ? newNodeId : connectingNodeId.current,
            target: isLeftSide ? connectingNodeId.current : newNodeId,
          };
  
          setNodes((nds) => [...nds, newNode]);
          setEdges((eds) => [...eds, newEdge]);
  
          addOrUpdateFile(
            fileName,
            JSON.stringify({ nodes: [...nodes, newNode], edges: [...edges, newEdge] })
          );
          startSaveTimer();
        }
      }
    },
    [nodes, edges, setNodes, setEdges, addOrUpdateFile, fileName, reactFlowInstance, startSaveTimer]
  );

  
  // Update node label when editing
  const handleNodeLabelChange = useCallback(
    (id, newLabel) => {
      setNodes((nds) => {
        const updatedNodes = nds.map((node) =>
          node.id === id ? { ...node, data: { ...node.data, label: newLabel } } : node
        );
        addOrUpdateFile(fileName, JSON.stringify({ nodes: updatedNodes, edges }));
        startSaveTimer(); // Start or reset the timer
        return updatedNodes;
      });
    },
    [addOrUpdateFile, fileName, edges, startSaveTimer]
  );

  // Handle edge connection
  const onConnect = useCallback(
    (params) => {
      const newEdges = addEdge(params, edges);
      setEdges(newEdges);
      addOrUpdateFile(fileName, JSON.stringify({ nodes, edges: newEdges }));
      startSaveTimer(); // Start or reset the timer
    },
    [edges, nodes, addOrUpdateFile, fileName, setEdges, startSaveTimer]
  );

  // Handle node and edge changes and save them
  const handleNodesChange = useCallback(
    (changes) => {
      onNodesChange(changes);
      addOrUpdateFile(fileName, JSON.stringify({ nodes, edges }));
      startSaveTimer(); // Start or reset the timer
    },
    [onNodesChange, addOrUpdateFile, fileName, nodes, edges, startSaveTimer]
  );

  const handleEdgesChange = useCallback(
    (changes) => {
      onEdgesChange(changes);
      addOrUpdateFile(fileName, JSON.stringify({ nodes, edges }));
      startSaveTimer(); // Start or reset the timer
    },
    [onEdgesChange, addOrUpdateFile, fileName, nodes, edges, startSaveTimer]
  );

  const proOptions = { hideAttribution: true };
  return parsedJson ? (
    <div style={{ width: '100%', height: '95vh' }}>
      <ReactFlow
        nodes={nodes.map((node) => ({
          ...node,
          type: 'mindmap',
          data: {
            ...node.data,
            onLabelChange: handleNodeLabelChange,
          },
          style: {
            ...node.style,
            width: 'auto', // Allow node width to adjust to content
          },
        }))}
        edges={edges}
        onNodesChange={handleNodesChange}
        onEdgesChange={handleEdgesChange}
        onConnectStart={onConnectStart}
        onConnectEnd={onConnectEnd}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        proOptions={proOptions}
        minZoom={0.05}
        fitView
        nodesDraggable={isInteractive}
        nodesConnectable={isInteractive}
        elementsSelectable={isInteractive}
      >
        <Background />
        <CustomControls getLayoutedElements={getLayoutedElements} setInteractivity={setIsInteractive} />
      </ReactFlow>
    </div>
  ) : (
    <div>Error: Invalid JSON input</div>
  );
};

export default ReactFlowChart;
