import React, { useState, useEffect } from 'react';
import './SideNavBar.css';
import { FaBars, FaHome, FaTimes, FaTrashAlt, FaUser, FaQuestionCircle, FaCoins, FaSignOutAlt, FaRegCreditCard } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { signOut as amplifySignOut } from '@aws-amplify/auth';
import getUserData from '../userManagement/getUserData';
import { getCurrentUser } from '@aws-amplify/auth';
import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  styles: {
    global: () => ({
      body: {
        bg: '', // No background color
        color: '', // No default text color
        lineHeight: 'base', // Optional: set the line-height to Chakra's base line height
      },
      '*': {
        border: '0px', // Removes borders
        margin: '0px', // Removes margins
        padding: '0px', // Removes padding
        // You can continue to reset or set any global styles you need
      },
      // You can also target other elements like 'a', 'p', 'h1', etc.
    }),
  },
  // Other customizations like component styles can go here
});


function SideNavbar({ onMyMapsClick, triggerUpdate, isFirstMindMap}) {
  const navigate = useNavigate();
  const [isAccountLoading, setIsAccountLoading] = useState(false); // New state for loading indicator
  const [navData, setNavData] = useState({
    userName: '',
    userEmail: '',
    currentPlan: '',
    currentUsage: 0,
    monthlyLimit: 0,
    isPaidUser :'',
    // Add other relevant state properties as derived from your NavBar component
  });

   const [activeItem, setActiveItem] = useState(''); // State to track active item
    
  // New state for pricing popup visibility
    const [isPricingPopupOpen, setPricingPopupOpen] = useState(false);

    const [sidebarVisible, setSidebarVisible] = useState(false); // State to control sidebar visibility

      // Toggle sidebar visibility
      const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);
      };


    // New function to toggle the pricing popup
    const togglePricingPopup = () => {
      setPricingPopupOpen(!isPricingPopupOpen);
    };

    const handleUpgradeNavigation = () => {
      window.open('/pricing', '_blank');
    }

    const handleNavItemClick = () => {
        onMyMapsClick();
    };

    const handleCreditsClick = () => {
      window.open('/how-credits-work', '_blank');
    }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUserData();
        const condition = data.MembershipPlan === "Paid" || data.MembershipPlan === "Pro Plan" || data.MembershipPlan === "Starter Plan" || data.MembershipPlan === "Pro" || data.MembershipPlan === 'Annual Pro Plan';
        setNavData({
          userName: data.Name,
          userEmail: data.Email,
          currentPlan: data.MembershipPlan,
          currentUsage: data.userUsage.CurrentMonthUsage,
          monthlyLimit: data.userUsage.MonthlyLimit,
          isPaidUser: condition,
          // Map additional user data fields as needed
        });
      } catch (error) {
        //console.error('Error fetching user data:', error);
      }
    };
    
    fetchData();
  }, [triggerUpdate]);

    const handleMyAccount = async () => {
    try {
      setIsAccountLoading(true);

      const user = await getCurrentUser();
      if (!user) {
        //console.error('User not found');
        throw new Error("User not found");
      }

      const userId = user.userId;

      const response = await fetch('https://daffzuzdjd.execute-api.us-east-1.amazonaws.com/dev/', { // for testing use this "https://hi40r02nx1.execute-api.us-east-1.amazonaws.com/dev" production url is -> "https://daffzuzdjd.execute-api.us-east-1.amazonaws.com/dev/" in production
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId }),
      });

      const responseBody = await response.text(); // Get the response body as text

      const data = JSON.parse(responseBody); // Parse the text as JSON

      if (response.ok) {
        const innerData = JSON.parse(data.body); // Parse the inner JSON string

        if (innerData.url) {
          window.location.href = innerData.url;
        } else {
          throw new Error('URL not found in response data');
        }
      } else {
        throw new Error(data.error || 'Failed to retrieve billing portal URL');
      }
    } catch (error) {
      //console.error('Error in handleMyAccount:', error);
      // Handle the error (e.g., show a notification to the user)
    } finally {
      setIsAccountLoading(false); // Reset loading state to false
    }
  };

  const handleSignOut = async () => {
    try {
      const cacheKey = 'filesCache';
      const cacheExpiryKey = 'cacheExpiry';
      localStorage.removeItem(cacheKey);
      localStorage.removeItem(cacheExpiryKey);
      await amplifySignOut();
      navigate('/login'); // Redirect to the login page after signing out
    } catch (error) {
      //console.error('Error signing out: ', error);
    }
  };
  const handleSubscriptionClick = () => {
    window.open('/my-account', '_blank');
  };  

  return (
  <>
      <div className={`hamburger-menu ${!sidebarVisible ? 'open' : ''}`} onClick={toggleSidebar}>
        <FaBars />
      </div>
      <div className={`sidebar ${sidebarVisible ? 'open' : ''}`} translate="no" >
      <div className="logo" onClick={() => navigate('/')}>
        <img src="/logo.png" alt="Map This Logo" />
        <div className='plan-icon'>
        { navData.isPaidUser && (
                  <div className='plan-logo'>
                    <img src="/proPlan.png" alt="Pro plan icon" className="header-image"/>
                  </div>
              )}

      { navData.currentPlan === 'Pay Per Use' && (
                  <div className='plan-logo'>
                    <img src="/diamond.png" alt="Pay Per Use plan icon" className="header-image"/>
                  </div>
              )}

              { !navData.currentPlan === "Freemium" && (
                  <div className='plan-logo'>
                    <img src="/freePlan.png" alt="Free plan icon" className="header-image"/>
                  </div>
              )}
        </div>

      </div>
      
      <nav className="nav">
      <div className={`nav-item ${activeItem === 'mymaps' ? 'active' : ''}`} onClick={handleNavItemClick}>
          <FaHome /> My Maps
        </div>
        <button className={`nav-item ${activeItem === 'mysubscription' ? 'active' : ''}`} onClick={handleSubscriptionClick}>
          <FaRegCreditCard /> My Account
        </button>
      <div onClick={handleCreditsClick} className={`nav-item ${activeItem === 'coins' ? 'active' : ''}` }>
        <FaCoins />
        {(navData.monthlyLimit - navData.currentUsage) * 10}
        <div className="tooltip-container">
          <FaQuestionCircle className="tooltip-icon" />
          <span className="tooltip-text">Click to learn more 💡</span>
        </div>
      </div>

      </nav>
      {(navData.currentPlan === "Freemium" || navData.currentPlan === "MidTier") && !isFirstMindMap ? (
        <button className="upgrade-btn" onClick={handleUpgradeNavigation}>
          Upgrade to <strong>PRO</strong> ✨
        </button>
      ) : null}
      <a href="https://airtable.com/embed/appRgfGSKMliHxHSS/pagA4yYekAO500uNH/form" className="nav-item support" target="_blank" rel="noopener noreferrer">
        <FaQuestionCircle /> Support
      </a>
      <div className="nav-item signout" onClick={handleSignOut}>
        <FaSignOutAlt /> Sign Out
      </div>
      <div className="close-btn" onClick={toggleSidebar}>
          <FaTimes />
        </div>

        


    </div>
    </>
  );
}

export default SideNavbar;
