// cacheService.js
const CACHE_KEY = 'filesCache';
const CACHE_EXPIRY_KEY = 'cacheExpiry';
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

export const cacheService = {
    saveToCache: (data) => {
        try {
            localStorage.setItem(CACHE_KEY, JSON.stringify(data));
            localStorage.setItem(CACHE_EXPIRY_KEY, Date.now() + CACHE_DURATION);
        } catch (error) {
            console.warn('Error saving to cache:', error);
        }
    },

    getFromCache: () => {
        try {
            const cachedData = localStorage.getItem(CACHE_KEY);
            const cacheExpiry = localStorage.getItem(CACHE_EXPIRY_KEY);
            
            if (cachedData && cacheExpiry && Date.now() < parseInt(cacheExpiry)) {
                return JSON.parse(cachedData);
            }
            return null;
        } catch (error) {
            console.warn('Error reading from cache:', error);
            return null;
        }
    },

    clearCache: () => {
        try {
            localStorage.removeItem(CACHE_KEY);
            localStorage.removeItem(CACHE_EXPIRY_KEY);
        } catch (error) {
            console.warn('Error clearing cache:', error);
        }
    }
};