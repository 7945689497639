import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  CircularProgress
} from '@mui/material';

const PricingCard = ({ 
  plan,
  billingCycle,
  isSaleActive,
  membershipPlan,
  isLoggedIn,
  onUpgradeClick,
  loading,
  couponCode: propCouponCode 
}) => {
  const [couponCode, setCouponCode] = useState(propCouponCode || '');
  const [appliedDiscount, setAppliedDiscount] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [displayPrice, setDisplayPrice] = useState(null);
  const [couponError, setCouponError] = useState('');
  const [isValidating, setIsValidating] = useState(false);

  // Reset states when billing cycle changes
  useEffect(() => {
    setCouponCode('');
    setAppliedDiscount(0);
    setIsAnimating(false);
    setDisplayPrice(null);
    setCouponError('');
  }, [billingCycle]);

  // Auto-apply coupon code when provided as prop
  useEffect(() => {
    if (propCouponCode && plan.name !== 'Free') {
      setCouponCode(propCouponCode);
      applyCoupon();
    }
  }, [propCouponCode, plan.name]);

  useEffect(() => {
    setCouponCode(propCouponCode || '');
    setAppliedDiscount(0);
    setIsAnimating(false);
    setDisplayPrice(null);
    setCouponError('');
  }, [billingCycle, propCouponCode]);

  const calculateDiscountedPrice = (originalPrice, couponDetails) => {
    if (couponDetails.amount_off) {
      // For fixed amount discounts, convert from cents to dollars
      return originalPrice - (couponDetails.amount_off / 100);
    } else if (couponDetails.percent_off) {
      // For percentage discounts
      return originalPrice * (1 - couponDetails.percent_off / 100);
    }
    return originalPrice;
  };

  const animatePrice = (startPrice, endPrice) => {
    setIsAnimating(true);
    let currentPrice = startPrice;
    const steps = 30;
    const increment = (startPrice - endPrice) / steps;
    
    const interval = setInterval(() => {
      currentPrice -= increment;
      setDisplayPrice(currentPrice);
      
      if (currentPrice <= endPrice) {
        clearInterval(interval);
        setDisplayPrice(endPrice);
        setIsAnimating(false);
      }
    }, 50);
  };

  const applyCoupon = async () => {
    if (!couponCode.trim()) return;
  
    setIsValidating(true);
    setCouponError('');
  
    try {
      const response = await fetch('https://gga3xm96o5.execute-api.us-east-1.amazonaws.com/couponValidator', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          couponCode: couponCode,
        }),
      });
  
      const data = await response.json();
  
      // Parse the backend response body (it comes as a JSON string)
      const parsedBody = JSON.parse(data.body);
  
      if (parsedBody.error) {
        setCouponError(parsedBody.error);
        return;
      }
  
      if (parsedBody.valid) {
        setAppliedDiscount(
          parsedBody.coupon.percent_off || parsedBody.coupon.amount_off / 100
        );
  
        const startPrice =
          billingCycle === 'monthly' ? plan.priceMonthly : plan.priceYearly;
        const endPrice = calculateDiscountedPrice(startPrice, parsedBody.coupon);
  
        // Animate to the new price
        animatePrice(startPrice, endPrice);
      } else {
        setCouponError('Invalid or expired coupon code');
      }
    } catch (error) {
      console.error('Error validating coupon:', error);
      setCouponError('Error validating coupon. Please try again.');
    } finally {
      setIsValidating(false);
    }
  };
  

  const formatPrice = (price) => {
    if (!price && price !== 0) return '0';
    if (price % 1 === 0) {
      return <span>{price}</span>;
    }
    const parts = price.toFixed(2).split('.');
    return (
      <>
        <span>{parts[0]}</span>
        <small style={{ fontSize: '14px' }}>.{parts[1]}</small>
      </>
    );
  };

  const ProPlanCardStyle = {
    background: 'linear-gradient(45deg, #edd1d3, #f4a4a6)',
    backgroundSize: '200% 200%',
    animation: 'gradientMove 5s ease infinite',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transform: 'scale(1.05)',
    transition: 'transform 0.3s ease',
  };

  return (
    <>
      {plan.name === 'Pro' && (
        <Typography variant="caption" component="div" id="current-plan-badge"
          style={{ 
            padding: '10px', 
            paddingBottom:'24px',
            textAlign: 'center', 
            color: (membershipPlan === 'Pro Plan' || membershipPlan === 'Paid') ? 'green' : '#f5f5f5',
            borderRadius: '10px 10px 0 0',
            backgroundColor: (membershipPlan === 'Pro Plan' || membershipPlan === 'Paid') ? '#dff0d8' : '#f5f5f5',
          }}>
          {(membershipPlan === 'Pro Plan' || membershipPlan === 'Paid') ? 'Current Plan' : ''}
        </Typography>
      )}
      {plan.name === 'Pro' && isSaleActive && billingCycle === 'monthly' && 
       !(membershipPlan === 'Pro Plan' || membershipPlan === 'Paid') && (
        <Typography variant="caption" component="div" 
          style={{ 
            padding: '10px',
            textAlign: 'center',
            fontWeight: 'bold',
            color: 'black',
            borderRadius: '10px 10px 0 0',
            background: 'linear-gradient(45deg, #bfd5e3 50%, #80c0e8)',
          }}>
          <img src="shine.png" style={{ width: '16px' }} alt="Check mark" />
          Save 85% for 3 months. Offer ends on 15th July 2024
        </Typography>
      )}
      {plan.name === 'Free' && (
        <Typography variant="caption" component="div" 
          style={{ 
            padding: '10px',
            textAlign: 'center',
            color: membershipPlan === 'Freemium' ? 'green' : '#f5f5f5',
            borderRadius: '10px 10px 0 0',
            backgroundColor: membershipPlan === 'Freemium' ? '#dff0d8' : '#f5f5f5',
          }}>
          {membershipPlan === 'Freemium' ? 'Current Plan' : ''}
        </Typography>
      )}
      <Card raised style={{
        ...(plan.name === 'Pro' ? ProPlanCardStyle : {}),
        backgroundColor: plan.cardColor,
        borderRadius: plan.borderRadiusMonthly,
      }}>
        <CardContent>
          <Typography variant="p" component="div" 
            style={{ textAlign: 'center', fontSize: '40px' }} gutterBottom>
            {plan.name}
          </Typography>
          <PriceDisplay 
            plan={plan}
            billingCycle={billingCycle}
            isSaleActive={isSaleActive}
            formatPrice={formatPrice}
            displayPrice={displayPrice}
            isAnimating={isAnimating}
          />
          
          {plan.name !== 'Free' && (
            <div className="coupon-section mt-4">
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <TextField
                  size="small"
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value.toUpperCase())}
                  placeholder="Enter coupon"
                  error={!!couponError}
                  disabled={isValidating}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      height: '28px',
                      fontSize: '0.75rem',
                      '& fieldset': {
                        borderRadius: '4px',
                      },
                    },
                    '& .MuiOutlinedInput-input': {
                      padding: '4px 8px',
                    },
                    flex: 1
                  }}
                />
                <Button
                  onClick={applyCoupon}
                  variant="outlined"
                  color="primary"
                  size="small"
                  id="apply-coupon-button"
                  disabled={isValidating || !couponCode.trim()}
                  sx={{ 
                    py: 0.5,
                    px: 2,
                    fontSize: '0.75rem',
                    minWidth: '60px',
                    height: '28px',
                    textTransform: 'none',
                    borderRadius: '4px'
                  }}
                >
                  {isValidating ? (
                    <CircularProgress size={16} />
                  ) : (
                    'Apply'
                  )}
                </Button>
              </div>
              {couponError && (
                <Typography 
                  variant="caption" 
                  color="error" 
                  sx={{ display: 'block', mt: 1 }}
                >
                  {couponError}
                </Typography>
              )}
            </div>
          )}
          
          {isLoggedIn && plan.name !== 'Free' && (
            <Button
              id="upgrade-button"
              onClick={() =>
                onUpgradeClick({
                  planName: plan.name,
                  couponCode: couponCode.trim() // Pass the applied coupon code
                })
              }
              variant="contained"
              color="primary"
              sx={{ 
                mb: 2,
                mx: 'auto',
                display: 'block',
                borderRadius: '24px',
                paddingLeft: '60px',
                paddingRight: '60px',
                marginTop:'14px'
              }}
              disabled={
                (plan.name === 'Free' && membershipPlan === 'Freemium') || 
                loading ||
                (plan.name === 'Pro' && billingCycle === 'yearly' && membershipPlan === 'Annual Pro Plan') ||
                ((plan.name === 'Pro' && billingCycle === 'monthly' && membershipPlan === 'Pro Plan') || 
                (plan.name === 'Pro' && billingCycle === 'monthly' && membershipPlan === 'Paid'))
              }
            >
              {loading ? 'Processing...' : (plan.name === 'Free' ? 'Sign up' : 'Upgrade')}
            </Button>
          )}
          <Typography variant="subtitle2" style={{ paddingBottom: '10px', paddingTop: '10px' }} gutterBottom>
            {plan.name === 'Free' ? 'ENJOY, FOR FREE' : 'EVERYTHING IN FREE, PLUS:'}
          </Typography>
          <Features features={plan.features} />
          <Limitations limitations={plan.limitations} />
        </CardContent>
      </Card>
    </>
  );
};

const PriceDisplay = ({ plan, billingCycle, isSaleActive, formatPrice, displayPrice, isAnimating }) => {
  const renderPriceContent = () => {
    if (isAnimating || displayPrice !== null) {
      return (
        <div style={{ transition: 'all 0.3s ease' }}>
          {/* Always show original price crossed out */}
          <StrikethroughPrice 
            price={12} 
            formatPrice={formatPrice} 
          />
          &nbsp;
          {/* Show animated discounted price */}
          <span style={{ fontSize: '20px', fontWeight: '200' }}>$</span>
          {formatPrice(displayPrice)}
          <span style={{ fontSize: '0.7rem', fontWeight: '100' }}>/month</span>
        </div>
      );
    }

    if (plan.name === 'Pro' && isSaleActive && billingCycle === "monthly" && plan.discountMonthly !== undefined) {
      return (
        <>
          <StrikethroughPrice 
            price={12} 
            formatPrice={formatPrice} 
          />
          <RegularPrice 
            price={plan.discountMonthly} 
            formatPrice={formatPrice} 
          />
        </>
      );
    }
    
    return (
      <>
        {/* Always show $12 crossed out for Pro plan */}
        {plan.name === 'Pro' && (
          <>
            <StrikethroughPrice 
              price={12} 
              formatPrice={formatPrice} 
            />
            &nbsp;
          </>
        )}
        <span style={{ fontSize: '20px', fontWeight: '200' }}>$</span>
        {billingCycle === 'monthly' ? formatPrice(plan.priceMonthly) : formatPrice(plan.priceYearly)}
        <small style={{ fontSize: '0.7rem', fontWeight: '100', textAlign: 'center' }}>
          {plan.priceYearly > 0 ? ' /month' : ''}
        </small>
      </>
    );
  };

  return (
    <Typography variant="h6" component="div" style={{ textAlign: 'center' }} gutterBottom>
      <div>
        {renderPriceContent()}
      </div>
      {(billingCycle === 'yearly' && plan.priceMonthly > 0) && (
        <p style={{ fontSize: '16px', fontWeight: '500', textAlign: 'center' }}>
          <i style={{fontSize:'14px'}}>Billed annually</i>
        </p>
      )}
    </Typography>
  );
};

const StrikethroughPrice = ({ price, formatPrice }) => (
  <div style={{ position: 'relative', display: 'inline-block', fontSize: '20px', fontWeight: '200' }}>
    <span style={{ position: 'relative', zIndex: 1, fontSize: '12px' }}>
      <span style={{ fontSize: '20px' }}>$</span>
      <span style={{ fontSize: '2.5rem', fontWeight: '700' }}>{formatPrice(price)}</span>
      <span style={{ fontSize: '12px' }}>/month</span>
    </span>
    <span style={{
      position: 'absolute',
      top: '60%',
      left: 0,
      width: '100%',
      borderTop: '5px solid red',
      zIndex: 10,
      transform: 'translateY(-50%)'
    }}></span>
  </div>
);

const RegularPrice = ({ price, formatPrice }) => (
  <div style={{ display: 'inline-block', fontSize: '20px', fontWeight: '200' }}>
    $<span style={{ fontSize: '2.5rem', fontWeight: '700' }}>{formatPrice(price)}</span>
    <span style={{ fontSize: '12px' }}>/month</span>
  </div>
);

const Features = ({ features }) => (
  <>
    {features.map((feature, idx) => (
      <Typography key={idx} variant="body1" color="primary">
        <span style={{ marginRight: '10px' }}>
          <img src="check-circle.svg" style={{ marginRight: '10px' }} alt="Check mark" />
        </span>
        {feature}
      </Typography>
    ))}
  </>
);

const Limitations = ({ limitations }) => (
  <>
    {limitations.map((limitation, idx) => (
      <Typography 
        key={idx} 
        style={{
          background: '#f55f5f',
          borderRadius: '10px',
          marginBottom: '4px',
          color: 'white'
        }} 
        variant="body1" 
        color="primary"
      >
        <span style={{ marginRight: '10px' }}>
          <img src="cross.png" style={{ marginRight: '10px', width: '24px' }} alt="Cross mark" />
        </span>
        <s>{limitation}</s>
      </Typography>
    ))}
  </>
);

export default PricingCard;