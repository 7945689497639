import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; 
import './NewHero.css';
import { useSpring, animated } from 'react-spring';
import MindmapSumComponent from '../../util/MindmapSumComponent';

// Separate the styled button component
const StyledButton = ({ onClick, children }) => {
  return (
    <div 
      onClick={onClick}
      className="styled-button"
      style={{
        alignItems: 'center',
        border: '2px solid #002b31',
        borderRadius: '42px',
        display: 'flex',
        justifyContent: 'center',
        padding: '14px 25px',
        fontFamily: '"Satoshi Variable-Bold", Helvetica',
        fontSize: '20px',
        fontWeight: 700,
        letterSpacing: '0.64px',
        lineHeight: '28px',
        color: '#002b31',
        textDecoration: 'none',
        transition: 'background-color 0.3s ease, color 0.3s ease',
        cursor: 'pointer',
      }}
      onMouseOver={(e) => {
        e.currentTarget.style.backgroundColor = '#e6f7ff';
        e.currentTarget.style.color = '#000';
      }}
      onMouseOut={(e) => {
        e.currentTarget.style.backgroundColor = 'transparent';
        e.currentTarget.style.color = '#002b31';
      }}
    >
      {children}
    </div>
  );
};

// Separate animated text component
const AnimatedText = ({ userInput, isAnimated, defaultPhrases }) => {
  const [text, setText] = useState(userInput || "PDFs");
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const typingSpeed = isDeleting ? 100 : 200;
  const phrases = isAnimated ? defaultPhrases : [userInput];

  useEffect(() => {
    if (!isAnimated) {
      setText(userInput || "PDFs");
      return;
    }

    const currentIndex = loopNum % phrases.length;
    const fullText = phrases[currentIndex];

    const handleTyping = () => {
      setText(current => {
        const baseText = isDeleting
          ? current.slice(0, current.length - 1)
          : current.length < fullText.length
          ? current + fullText[current.length]
          : current;

        if (!isDeleting && baseText === fullText) {
          setTimeout(() => setIsDeleting(true), 1000);
        } else if (isDeleting && baseText === '') {
          setIsDeleting(false);
          setLoopNum(loopNum + 1);
        }

        return baseText || '';
      });
    };

    const timer = setTimeout(handleTyping, typingSpeed);
    return () => clearTimeout(timer);
  }, [text, isDeleting, loopNum, phrases, isAnimated, typingSpeed, userInput]);

  return text;
};

const NewHero = ({ userInput, userOutput = "Mind Maps", CTAText = "Start for Free", demoLink }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [sumOfMindmaps, setSumOfMindmaps] = useState(0);
  
  const handleLogin = () => {
    navigate('/login');
  };

  const defaultPhrases = ["PDFs", "Notes", "Reports"];
  const isAnimated = !userInput;

  const { number } = useSpring({
    from: { number: 0 },
    to: { number: sumOfMindmaps },
    config: { duration: 10000, easing: (t) => --t * t * t + 1 },
  });

  const shouldHideTrustBuilding = location.pathname.startsWith('/tools');

  return (
    <>
      <div className="hero-container">
        <div style={{ display: 'none' }}>
          <MindmapSumComponent onSumUpdate={setSumOfMindmaps} />
        </div>

        <div className="hero-text">
          <h1>
            <span>Turn your </span>
            <span className="highlight-pdf">
              <AnimatedText 
                userInput={userInput} 
                isAnimated={isAnimated} 
                defaultPhrases={defaultPhrases}
              />
            </span> into <br />
            <span className="highlight-mindmap">{userOutput}</span>
          </h1>
          <p className="hero-description">
            Say goodbye to information overload and hello to streamlined knowledge retention and sharing
          </p>
          <StyledButton onClick={handleLogin}>{CTAText}</StyledButton>
          <div className="hero-note">*No credit card required</div>
        </div>

        <div className="video-container">
          {demoLink ? (
            <img src={demoLink[0]} alt={`${userInput} to ${userOutput}`} width="760" height="450" />
          ) : (
            <iframe
              width="760"
              height="450"
              src={`https://www.youtube.com/embed/jiBgfDYFZGo?autoplay=1&loop=1&playlist=jiBgfDYFZGo&mute=1`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          )}
        </div>
      </div>

      {!shouldHideTrustBuilding && (
        <div className="trust-building">
          <div className="trust-building-container hero-review">
            <img src={`${process.env.PUBLIC_URL}/quotation-image.png`} alt="quote" />
            <p className="review">
              Impressed with the efficiency and simplicity. It streamlines research tasks and improves productivity. A must-have for academics.
            </p>
            <p className="reviewer-designation"> - Jamie Lee</p>
          </div>

          <div className="trust-building-container hero-join">
            <p>Join 250,000+ happy mind mappers and counting </p>
            <div className="user-images">
              <img src={`${process.env.PUBLIC_URL}/users/andrew.jpg`} alt="User 1" />
              <img src={`${process.env.PUBLIC_URL}/users/katrina.jpg`} alt="User 2" />
              <img src={`${process.env.PUBLIC_URL}/users/moe.jpg`} alt="User 3" />
              <img src={`${process.env.PUBLIC_URL}/users/giselle.jpg`} alt="User 4" />
              <img src={`${process.env.PUBLIC_URL}/users/christina.jpg`} alt="User 5" />
            </div>
          </div>

          <div className="trust-building-container hero-generated-today">
            <animated.span className="highlight-mindmap counter">
              {number.interpolate((value) => Math.floor(value))}
            </animated.span>
            <div style={{ padding: '16px' }}>Maps generated in last 24 hours</div>
            <img src={`${process.env.PUBLIC_URL}/Backicon.png`} alt="PDF to Mind Map Icon" />
          </div>
        </div>
      )}
    </>
  );
};

export default NewHero;