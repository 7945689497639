import React, { useRef, useLayoutEffect, useEffect } from 'react';
import { Handle, Position } from 'reactflow';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import './MindMapNode.css';

function MindMapNode({ id, data, selected }) {
  const textareaRef = useRef(null);
  const updateNodeLabel = data.onLabelChange;

  // Adjust the height of the textarea based on content
  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '16px'; // Reset height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Adjust to scrollHeight
    }
  };

  useLayoutEffect(() => {
    adjustTextareaHeight();
  }, [data.label]);

  useEffect(() => {
    // Focus the textarea when the node is created
    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.focus({ preventScroll: true });
      }
    }, 1);
  }, []);

  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        style={{ visibility: data.hideLeft ? 'hidden' : 'visible' }}
        data-tooltip-id={`handle-left-${id}`}
        data-tooltip-content="Hold and drag to create a new node"
      />
      <Tooltip id={`handle-left-${id}`} />

      <div className={`inputWrapper ${selected ? 'selected' : ''}`}>
        <div className="dragHandle">
          <svg viewBox="0 0 24 24">
            <path
              fill="#333"
              stroke="#333"
              strokeWidth="1"
              d="M15 5h2V3h-2v2zM7 5h2V3H7v2zm8 8h2v-2h-2v2zm-8 0h2v-2H7v2zm8 8h2v-2h-2v2zm-8 0h2v-2H7v2z"
            />
          </svg>
        </div>
        <textarea
          value={data.label}
          onChange={(evt) => {
            updateNodeLabel(id, evt.target.value);
            adjustTextareaHeight();
          }}
          className="inputNode"
          ref={textareaRef}
          style={{ resize: 'none', overflow: 'hidden', minHeight: '30px' }}
        />
      </div>

      <Handle
        type="source"
        position={Position.Right}
        style={{ visibility: data.hideRight ? 'hidden' : 'visible' }}
        data-tooltip-id={`handle-right-${id}`}
        data-tooltip-content="Hold and drag to create a new node"
      />
      <Tooltip id={`handle-right-${id}`} />
    </>
  );
}

export default MindMapNode;
