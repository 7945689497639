import { useCallback } from 'react';
import dagre from '@dagrejs/dagre';
import { useReactFlow } from 'reactflow';

const useLayoutedElements = () => {
  const { getNodes, setNodes, getEdges, fitView } = useReactFlow();

  const getLayoutedElements = useCallback(() => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    dagreGraph.setGraph({ rankdir: 'LR', nodesep: 100, ranksep: 150 }); // TB: Top to Bottom layout, customize spacing as needed

    // Add nodes to the graph
    const nodes = getNodes();
    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, {
        width: node.measured?.width || 150,
        height: node.measured?.height || 50,
      });
    });

    // Add edges to the graph
    const edges = getEdges();
    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    // Run the Dagre layout algorithm
    dagre.layout(dagreGraph);

    // Update node positions
    const layoutedNodes = nodes.map((node) => {
      const { x, y } = dagreGraph.node(node.id);
      return {
        ...node,
        position: { x, y },
        // You can also lock the position if you don't want further dragging:
        // positionAbsolute: { x, y },
      };
    });

    setNodes(layoutedNodes);

    // Adjust the view to fit all elements
    window.requestAnimationFrame(() => fitView());
  }, [getNodes, setNodes, getEdges, fitView]);

  return { getLayoutedElements };
};

export default useLayoutedElements;
