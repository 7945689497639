import AWS from 'aws-sdk';
import { fetchAuthSession } from '@aws-amplify/auth';
import Airtable from 'airtable';
import updateUserGroupMailterlite from './updateUserGroupMailterlite';

const updateUserUsage = async (creditsNeeded, isFirstMindMap = false) => {
    const mailerliteApiKey = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiN2NlYjNmYmNjYWQyODJiY2VlY2JjNGJhOTAxMmViYWFlMDI4ZTNiZjcwYzVkNGY3MTQzMWFmZTNlZmNiNzk5MmQzNzFjY2NkMzBmMjAwNWYiLCJpYXQiOjE3MTQxNjA0OTEuMTI5MDk2LCJuYmYiOjE3MTQxNjA0OTEuMTI5MDk4LCJleHAiOjQ4Njk4MzQwOTEuMTI1MTg0LCJzdWIiOiI5MjE3MTciLCJzY29wZXMiOltdfQ.XmJtblrj7tP45bOw-XOIU-Grk7oP2JRGF_JyRQ69vr82yiyBdDMFLHc2xQcDX5QEP7ntSkExiQ_1c-k3fWfwG6tYgkMOzUq6vsT1j_mKBETM9MELV-hQ-uyRy-hEcl1CFpw3-2HKnnbYg_TO88041y7zD0WC61HZBIYJJM5mdGCBNnKY324sbnHRM15xLKPWynp1zvus-NfJyhiDL3GCqQWVC6GNlhOy80z2o7pvrIXMcUOwBpmfTDoH43p_CbcRL9GKBEfNDpscN_LefxcxOxWGS2sORY-qtqjXMciZqkCk72ATIDF1xH08eNrriRhJb5Z73SfCxbyjVMhAXYybYP5ug3_vbTmVg8KpQ55j7l3WL5wm6c6619AWkC64XPeqKaBY4AEoYg3B7hQYzh2z4A_0c-qpEsEbJTpF7ktQju7rflD4Qx1ufw57-9W5ZK-X1oRqJ6H8-7t3lpGE8q9Der89X2LwbUC6nYsrcOZERO2CVUFijqJUbfqtu_9SKXJdfP36Z80bhPEl8k57pNa8xRssucS8OjHE0DnVQAbe6bQfjP-oScjqEK32863p0IzrSO2UVKG1BqFALKX8SpWUL9pUsBI4x2qLeDHyBCMF3McxqpL_GmQOYmuil9lAncXRnprbRo8Ptiau16GOz_EXXHfO0KnVtRIKhDHC9H1EYqs';  

    try {
        const session = await fetchAuthSession();

        if (session && session.credentials) {
            AWS.config.update({
                region: 'us-east-1',
                credentials: {
                    accessKeyId: session.credentials.accessKeyId,
                    secretAccessKey: session.credentials.secretAccessKey,
                    sessionToken: session.credentials.sessionToken
                }
            });

            const dynamoDb = new AWS.DynamoDB.DocumentClient();
            const tableName = 'pdftomindmapUsers';
            const userId = session.userSub;

            const getParams = {
                TableName: tableName,
                Key: { 'userID': userId }
            };

            try {
                const data = await dynamoDb.get(getParams).promise();
                if (data && data.Item) {
                    const currentMonthUsage = data.Item.userUsage.CurrentMonthUsage || 0;
                    const newUsage = currentMonthUsage;
                    var addedToFirstMappers = data.Item.addedToFirstMappers || true;
                    const userEmail = data.Item.Email;
                    const userName = data.Item.Name;
                    var emailSignup = data.Item.emailSignup || false;
                    const firstMappersGroup = '119711863725360969';
                    const subscriberData = 
                    { 
                      email: userEmail,
                      "fields": {
                          "name": userName.split(' ')[0],
                          "last_name": userName.split(' ')[1]
                        },
                        "groups":[
                          firstMappersGroup
                        ]
                    };
                    /*
                    if(!addedToFirstMappers && emailSignup){
                        await updateUserGroupMailterlite(mailerliteApiKey, subscriberData);
                        addedToFirstMappers = true;
                    }*/

                    const currentUsage = data.Item.userUsage.CurrentMonthUsage || 0;
                    const membershipPlan = data.Item.MembershipPlan;

                    if (currentUsage <= 0.5 && (membershipPlan === 'Freemium' || membershipPlan === 'Pay Per Use')) {
                        const apiEndpoint = 'https://y9y9ktnx32.execute-api.us-east-1.amazonaws.com/dev';
                        try {
                            const response = await fetch(apiEndpoint, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({ userID: userId }),
                            });
                            if (!response.ok) {
                                throw new Error(`HTTP error! status: ${response.status}`);
                            }
                            const result = await response.json();
                            //console.log('API call successful for low credits', result);
                        } catch (apiError) {
                            //console.error('Error calling API for low credits:', apiError);
                        }
                    }

                    const updateParams = {
                        TableName: tableName,
                        Key: { 'userID': userId },
                        UpdateExpression: 'set userUsage.CurrentMonthUsage = :newUsage, isFirstMindMap = :isFirst, addedToFirstMappers = :addedToFirstMappers, emailSignup = :emailSignup',
                        ExpressionAttributeValues: {
                            ':newUsage': newUsage,
                            ':isFirst': false,
                            ':addedToFirstMappers': addedToFirstMappers,
                            ':emailSignup': emailSignup
                        },
                        ReturnValues: 'UPDATED_NEW'
                    };

                    try {
                        const updateData = await dynamoDb.update(updateParams).promise();
                    } catch (updateErr) {
                        console.error("Error updating user CurrentMonthUsage:", updateErr);
                    }
                } else {
                    //console.log("No data found for this userID.");
                }
            } catch (getErr) {
                console.error("Error fetching data from DynamoDB:", getErr);
            }
            const airtableBase = new Airtable({apiKey: 'patoZsqAYhzghx1mM.fe360b7f678a595631f2ff3c15cd06c546b10351bde3efefd415508119c1c328'}).base('appRgfGSKMliHxHSS');
            const airtableTable = 'CreditsUsageStretch';

            const currentTime = new Date();
            const currentUTCTime = currentTime.toISOString();

            airtableBase(airtableTable).select({
                filterByFormula: `AND((DATETIME_DIFF({DateTime}, '${currentUTCTime}', 'seconds') <= 0), (DATETIME_DIFF('${currentUTCTime}', {WindowEndTime}, 'seconds') <= 0))`,
                view: "Grid view"
            }).firstPage(function(err, records) {
                if (err) { console.error(err); return; }
                
                if (records.length > 0) {
                    let recordId = records[0].id;
                    let currentCredits = records[0].get('CreditsUsed') || 0;
                    let currentNoOfMindMaps = records[0].get('MindMapsGenerated') || 0;
                    airtableBase(airtableTable).update(recordId, {
                        "CreditsUsed": currentCredits + creditsNeeded,
                        "MindMapsGenerated": currentNoOfMindMaps + 1
                    }, function(updateErr, updatedRecord) {
                        if (updateErr) { console.error(updateErr); return; }
                    });
                } else {
                    airtableBase(airtableTable).create({
                        "DateTime": currentTime,
                        "CreditsUsed": creditsNeeded,
                        "MindMapsGenerated": 1
                    }, function(createErr, newRecord) {
                        if (createErr) { console.error(createErr); return; }
                    });
                }
            });

        } else {
            //console.log("Session credentials are missing.");
        }
    } catch (error) {
        //console.error('Error in updating user CurrentMonthUsage:', error);
    }
};

export default updateUserUsage;