import React, { useState, useCallback } from 'react';
import { toPng } from 'html-to-image';
import { useReactFlow } from 'reactflow';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const downloadImage = (dataUrl) => {
  const link = document.createElement('a');
  link.download = 'map-this-mindmap-hd.png';
  link.href = dataUrl;
  link.click();
};

const DownloadButton = () => {
  const { getNodes, fitView, getViewport } = useReactFlow();
  const [isHovered, setIsHovered] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const applyTextRendering = () => {
    const textElements = document.querySelectorAll('.react-flow__viewport *');
    textElements.forEach(el => {
      if (el.textContent && el.textContent.trim().length > 0) {
        el.style.textRendering = 'geometricPrecision';
        el.style.webkitFontSmoothing = 'antialiased';
        el.style.mozOsxFontSmoothing = 'grayscale';
      }
    });
  };

  const generateImage = useCallback(async (viewport) => {
    const nodes = getNodes();
    const { x = 0, y = 0, zoom = 1 } = getViewport();
    
    // Get the ReactFlow container
    const reactFlowContainer = document.querySelector('.react-flow');
    if (!reactFlowContainer) return null;
    
    const containerBounds = reactFlowContainer.getBoundingClientRect();
    const viewportBounds = viewport.getBoundingClientRect();
    
    // Calculate the actual dimensions needed
    const width = containerBounds.width;
    const height = containerBounds.height;

    // Calculate scaling
    const baseScale = 5;
    const pixelRatio = window.devicePixelRatio || 1;
    const totalScale = baseScale * pixelRatio;

    try {
      applyTextRendering();

      // Prepare the viewport for capture
      const originalTransform = viewport.style.transform;
      viewport.style.transform = `translate(${x}px, ${y}px) scale(${zoom})`;

      const result = await toPng(viewport, {
        backgroundColor: '#ffffff',
        pixelRatio: totalScale,
        width: width,
        height: height,
        style: {
          textRendering: 'geometricPrecision',
          webkitFontSmoothing: 'antialiased',
          mozOsxFontSmoothing: 'grayscale',
        },
        filter: (node) => {
          const excludeClasses = ['react-tooltip', 'download-btn'];
          return !excludeClasses.some(className => 
            node.classList?.contains(className)
          );
        }
      });

      // Restore the original transform
      viewport.style.transform = originalTransform;
      
      return result;
    } catch (error) {
      console.error('Error generating image:', error);
      throw error;
    }
  }, [getNodes, getViewport]);

  const onClick = async () => {
    if (isProcessing) return;
    setIsProcessing(true);

    try {
      // Get the viewport element
      const viewport = document.querySelector('.react-flow__viewport');
      if (!viewport) throw new Error('Viewport not found');

      // Store original styles
      const originalStyles = {
        transform: viewport.style.transform,
        width: viewport.style.width,
        height: viewport.style.height,
        position: viewport.style.position
      };

      // Generate and download the image
      const dataUrl = await generateImage(viewport);
      if (dataUrl) {
        downloadImage(dataUrl);
      }

      // Restore original styles
      Object.assign(viewport.style, originalStyles);
    } catch (error) {
      console.error('Download failed:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  const buttonStyle = {
    backgroundColor: isHovered ? '#f0f0f0' : 'white',
    border: 'none',
    padding: '5px',
    cursor: isProcessing ? 'wait' : 'pointer',
    borderRadius: '5px',
    transition: 'background-color 0.2s ease',
    opacity: isProcessing ? 0.7 : 1,
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  };

  return (
    <button
      className="download-btn"
      onClick={onClick}
      style={buttonStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      disabled={isProcessing}
    >
      <img
        src="/icons8-download-90.png"
        alt="Download Image"
        style={{ 
          width: '18px', 
          height: '18px',
          opacity: isProcessing ? 0.5 : 1 
        }}
        data-tooltip-id="download"
        data-tooltip-content={isProcessing ? "Processing..." : "Download"}
      />
      {isProcessing && <span style={{ fontSize: '12px' }}>Processing...</span>}
      <Tooltip id="download" />
    </button>
  );
};

export default DownloadButton;