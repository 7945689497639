
import React, { useState, useEffect, useCallback } from 'react';
import { list, downloadData, remove, copy } from 'aws-amplify/storage';
import { FaTimes, FaSave,  FaFile, FaEllipsisV, FaSpinner } from 'react-icons/fa';
import './TileGallery.css'; 
import mermaid from 'mermaid';
import { getDocument } from 'pdfjs-dist/build/pdf';
import OptionsMenuModal from './OptionsMenuModal'; 
import Modal from './deleteMenu'; 
import RenameMindmapModal from './renameModal';
import FormatFileName from '../util/FormatFileName';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import './LandingPage/SalesBanner.css';
import RotatingSaleBanner from './RotatingSaleBanner';
import { cacheService } from './cacheService';


const cacheKey = 'filesCache';
const cacheExpiryKey = 'cacheExpiry';
const cacheExpiryTime = 60000; // 1 minute

const TileGallery = ({ onPdfSelect, onNewTileClick, onTxtSelect, isFirstMindMap, isPaidUser, isFreeOrPayPerUse }) => {
    const [pdfList, setPdfList] = useState([]);
    const [txtListAI, setTxtListAI] = useState([]);
    const [txtListInput, setTxtListInput] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState({});
    const [showMore, setShowMore] = useState(false);
    const [editMode, setEditMode] = useState(null);
    const [newFileName, setNewFileName] = useState('');
    const [filePreviews, setFilePreviews] = useState({});
    const [showOptionsMenu, setShowOptionsMenu] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [currentFileForOptions, setCurrentFileForOptions] = useState(null);
    const [currentFile, setCurrentFile] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [currentFileKeyForEdit, setCurrentFileKeyForEdit] = useState('');
    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
    const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0, hasSaleStarted: false });
    const [showRenameModal, setShowRenameModal] = useState(false);
    const [fileDetails, setFileDetails] = useState({});
    const location = useLocation();
    const navigate = useNavigate();
    const [showCallToAction, setShowCallToAction] = useState(true);
    const [fileTypeMap, setFileTypeMap] = useState({}); // State for storing chart type for each file

    const Button = styled.button`
        background-color: transparent;
        border: 1.5px solid #ffffff;
        border-radius: 42px;
        padding: 10px 25px;
        font-size: 16px;
        font-weight: 700;
        color: #ffffff;
        cursor: pointer;
        margin-left: 10px;
        &:hover {
            background-color: #dddddd;
        }
    `;



    const handleClosePopup = () => {
        setShowCallToAction(false); // Function to close the popup
    };
    {/*
    // Countdown logic
    useEffect(() => {
        const calculateTimeLeft = () => {
            const targetDate = new Date('July 15, 2024 00:00:00 GMT-0700');
            const now = new Date();
            const difference = targetDate - now;
    
            if (difference > 0) {
                const days = Math.floor(difference / (1000 * 60 * 60 * 24));
                const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
                const minutes = Math.floor((difference / (1000 * 60)) % 60);
                const seconds = Math.floor((difference / 1000) % 60);
                return { days, hours, minutes, seconds, hasSaleStarted: false };
            } else {
                return { days: 0, hours: 0, minutes: 0, seconds: 0, hasSaleStarted: true };
            }
        };
    
        // Set initial time left synchronously
        setTimeLeft(calculateTimeLeft());
    
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
    
        return () => clearInterval(timer);
    }, []);

    * */}

    /*const fetchFilesList = useCallback(async () => {
        const cachedFiles = localStorage.getItem(cacheKey);
        const cacheExpiry = localStorage.getItem(cacheExpiryKey);
    
        if (cachedFiles && cacheExpiry && Date.now() < cacheExpiry) {
            const { pdfList, txtListAI, txtListInput, filePreviews } = JSON.parse(cachedFiles);
            setPdfList(pdfList);
            setTxtListAI(txtListAI);
            setTxtListInput(txtListInput);
            setFilePreviews(filePreviews);
            return;
        }
    
        setLoading(true);
        const newFileDetails = {};
        const fileTypeMap = {};
        try {
            const response = await list({
                options: {
                    accessLevel: 'private',
                    listAll: true
                }
            });
            const sortedFiles = response.items.sort((a, b) => new Date(b.lastModified) - new Date(a.lastModified));
    
            sortedFiles.forEach(file => {
                newFileDetails[file.key] = {
                    lastModified: new Date(file.lastModified).toLocaleString(),
                    preview: 'Loading preview...'
                };
    
                if (file.key.endsWith('.pdf') || file.key.endsWith('ai_magic.txt') || file.key.endsWith('text_input.txt')) {
                    newFileDetails[file.key].preview = fetchFilePreview(file.key);
                }
            });
    
            setFileDetails(newFileDetails);
            const filesWithValidContent = [];
            const filePreviewsPromises = {};
    
            for (const file of response.items) {
                if (file.key.endsWith('.pdf') || file.key.endsWith('ai_magic.txt') || file.key.endsWith('text_input.txt')) {
                    const summaryFileName = file.key.replace(/\.(pdf|txt)$/, '_summary.txt');
                    try {
                        const downloadResult = await downloadData({
                            key: summaryFileName,
                            options: { accessLevel: 'private' }
                        }).result;
    
                        const textContent = await downloadResult.body.text();
                        let isValidMermaid = false;
                        let isValidReactFlowJSON = false;
    
                        // Check for Mermaid validity
                        try {
                            isValidMermaid = await mermaid.mermaidAPI.parse(textContent);
                            if (isValidMermaid) {
                                if (mermaid.detectType(textContent).includes('gantt')) {
                                    fileTypeMap[file.key] = 'gantt';
                                } else if (mermaid.detectType(textContent).includes('mindmap')) {
                                    fileTypeMap[file.key] = 'mindmap';
                                } else if (mermaid.detectType(textContent).includes('flowchart')) {
                                    fileTypeMap[file.key] = 'flowchart';
                                } else {
                                    fileTypeMap[file.key] = 'Unknown Chart Type';
                                }
                            }
                        } catch (error) {
                            console.warn('Not a valid Mermaid content');
                        }
    
                        // Check for React Flow JSON structure
                        try {
                            const parsedJSON = JSON.parse(textContent);
                            isValidReactFlowJSON = true;
                            fileTypeMap[file.key] = 'reactflowDiagram';
                        } catch (error) {
                            console.warn('Not a valid JSON content for React Flow');
                        }
    
                        // If either Mermaid or React Flow JSON is valid, add to the list
                        if (isValidMermaid || isValidReactFlowJSON) {
                            filesWithValidContent.push(file.key);
                            filePreviewsPromises[file.key] = fetchFilePreview(file.key);
                        }
                    } catch (error) {
                        console.error('Error fetching or parsing summary file:', error);
                    }
                }
            }
    
            const filePreviewsResults = await Promise.allSettled(Object.values(filePreviewsPromises));
            const newFilePreviews = {};
            Object.keys(filePreviewsPromises).forEach((key, index) => {
                newFilePreviews[key] = filePreviewsResults[index].status === 'fulfilled' ? filePreviewsResults[index].value : 'Preview not available';
            });
    
            setFilePreviews(newFilePreviews);
            setPdfList(filesWithValidContent);
            setFileTypeMap(fileTypeMap);
        } catch (error) {
            console.error('Error listing files:', error);
        } finally {
            setLoading(false);
        }
    }, []);*/

    const fetchFilesList = useCallback(async () => {
        // Try to get data from cache first
        const cachedData = cacheService.getFromCache();
        if (cachedData) {
            setPdfList(cachedData.pdfList || []);
            setTxtListAI(cachedData.txtListAI || []);
            setTxtListInput(cachedData.txtListInput || []);
            setFilePreviews(cachedData.filePreviews || {});
            setFileDetails(cachedData.fileDetails || {});
            setFileTypeMap(cachedData.fileTypeMap || {});
            // Still fetch fresh data in background
            fetchFreshData();
            return;
        }
    
        // If no cache, proceed with normal fetch
        await fetchFreshData();
    }, []);

    const fetchFreshData = async () => {
        setLoading(true);
        const newFileDetails = {};
        const fileTypeMap = {};
        
        try {
            const response = await list({
                options: {
                    accessLevel: 'private',
                    listAll: true
                }
            });
            
            const sortedFiles = response.items.sort((a, b) => new Date(b.lastModified) - new Date(a.lastModified));
            const filesWithValidContent = [];
            const filePreviewsPromises = {};
            
            // Process files as before
            for (const file of response.items) {
                newFileDetails[file.key] = {
                    lastModified: new Date(file.lastModified).toLocaleString(),
                    preview: 'Loading preview...'
                };
    
                if (file.key.endsWith('.pdf') || file.key.endsWith('ai_magic.txt') || file.key.endsWith('text_input.txt')) {
                    const summaryFileName = file.key.replace(/\.(pdf|txt)$/, '_summary.txt');
                    try {
                        const downloadResult = await downloadData({
                            key: summaryFileName,
                            options: { accessLevel: 'private' }
                        }).result;
    
                        const textContent = await downloadResult.body.text();
                        let isValidMermaid = false;
                        let isValidReactFlowJSON = false;
    
                        try {
                            isValidMermaid = await mermaid.mermaidAPI.parse(textContent);
                            if (isValidMermaid) {
                                if (mermaid.detectType(textContent).includes('gantt')) {
                                    fileTypeMap[file.key] = 'gantt';
                                } else if (mermaid.detectType(textContent).includes('mindmap')) {
                                    fileTypeMap[file.key] = 'mindmap';
                                } else if (mermaid.detectType(textContent).includes('flowchart')) {
                                    fileTypeMap[file.key] = 'flowchart';
                                } else {
                                    fileTypeMap[file.key] = 'Unknown Chart Type';
                                }
                            }
                        } catch (error) {
                            console.warn('Not a valid Mermaid content');
                        }
    
                        try {
                            const parsedJSON = JSON.parse(textContent);
                            isValidReactFlowJSON = true;
                            fileTypeMap[file.key] = 'reactflowDiagram';
                        } catch (error) {
                            console.warn('Not a valid JSON content for React Flow');
                        }
    
                        if (isValidMermaid || isValidReactFlowJSON) {
                            filesWithValidContent.push(file.key);
                            filePreviewsPromises[file.key] = fetchFilePreview(file.key);
                        }
                    } catch (error) {
                        console.error('Error fetching or parsing summary file:', error);
                    }
                }
            }
    
            const filePreviewsResults = await Promise.allSettled(Object.values(filePreviewsPromises));
            const newFilePreviews = {};
            Object.keys(filePreviewsPromises).forEach((key, index) => {
                newFilePreviews[key] = filePreviewsResults[index].status === 'fulfilled' 
                    ? filePreviewsResults[index].value 
                    : 'Preview not available';
            });
    
            // Update state
            setFilePreviews(newFilePreviews);
            setPdfList(filesWithValidContent);
            setFileDetails(newFileDetails);
            setFileTypeMap(fileTypeMap);
    
            // Save to cache
            cacheService.saveToCache({
                pdfList: filesWithValidContent,
                fileDetails: newFileDetails,
                filePreviews: newFilePreviews,
                fileTypeMap: fileTypeMap
            });
        } catch (error) {
            console.error('Error listing files:', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchFilesList();
    }, [fetchFilesList]);

    const handleFileClick = (fileName) => {
        setSelectedFile(fileName);
        if (fileName.endsWith('.pdf')) {
            onPdfSelect(fileName);
        } else {
            onTxtSelect(fileName);
        }
    };

    const handleEditOptionClick = (fileKey) => {
        setCurrentFileKeyForEdit(fileKey);
        setShowEditModal(true);
        setShowOptionsMenu(false); // Close the options menu
    };


    const handleOpenOptionsMenu = (fileName, event) => {
        event.stopPropagation();
        setCurrentFile(fileName);
        const formattedFileName = FormatFileName(fileName);
        setCurrentFileForOptions(formattedFileName);
        setShowOptionsMenu(true);

        const ellipsisBounds = event.currentTarget.getBoundingClientRect();
        let menuX = ellipsisBounds.left;
        let menuY = ellipsisBounds.bottom;
        const menuWidth = 200;
        const menuHeight = 150;

        setMenuPosition({ top: menuY, left: menuX });
    };

    const handleDeleteOptionClick = (fileName) => {
        setShowOptionsMenu(false);
        setShowDeleteModal(true);
    };

    const handleRenameOptionClick = () => {
        setShowRenameModal(true);
        setShowOptionsMenu(false);
    };

    const handleCloseRenameModal = () => {
        setShowRenameModal(false);
    };

    /*const handleRename = async (oldKey, newName) => {
        const { uuid, namePart, fileType } = parseFileKey(oldKey);
        const type = fileType.includes('_ai_magic') ? 'AI_Magic' : fileType.includes('_text_input') ? 'TextInput' : 'pdfs';
        let newKey;
        if (fileType === '.pdf') {
            newKey = `${uuid}-${newName}.pdf`;
        } else {
            newKey = `${type}/${uuid}_${newName}${fileType}`;
        }
        const newSummaryKey = newKey.replace(/(\.pdf|\.txt)$/, '_summary.txt');
        const oldSummaryKey = oldKey.replace(/(\.pdf|\.txt)$/, '_summary.txt');
        try {
            await copy({
                source: { key: oldKey, accessLevel: 'private' },
                destination: { key: newKey, accessLevel: 'private' }
            });
            await remove({ key: oldKey, options: { accessLevel: 'private' } });

            try {
                await copy({
                    source: { key: oldSummaryKey, accessLevel: 'private' },
                    destination: { key: newSummaryKey, accessLevel: 'private' }
                });
                await remove({ key: oldSummaryKey, options: { accessLevel: 'private' } });
            } catch (error) {
                console.warn(`Summary file issue: ${error.message}`);
            }
            const updateFileList = (list) => list.map(file => file === oldKey ? newKey : file);
            setPdfList(updateFileList(pdfList));
            setTxtListAI(updateFileList(txtListAI));
            setTxtListInput(updateFileList(txtListInput));
            setEditMode(null);
            setTimeout(() => {
                fetchFilesList();
            }, 500);
            setShowRenameModal(false);
        } catch (error) {
            console.error(`Error renaming file: ${error.message}`);
        }
    };*/

    const handleRename = async (oldKey, newName) => {
        const { uuid, namePart, fileType } = parseFileKey(oldKey);
        const type = fileType.includes('_ai_magic') ? 'AI_Magic' : fileType.includes('_text_input') ? 'TextInput' : 'pdfs';
        let newKey;
        if (fileType === '.pdf') {
            newKey = `${uuid}-${newName}.pdf`;
        } else {
            newKey = `${type}/${uuid}_${newName}${fileType}`;
        }
        const newSummaryKey = newKey.replace(/(\.pdf|\.txt)$/, '_summary.txt');
        const oldSummaryKey = oldKey.replace(/(\.pdf|\.txt)$/, '_summary.txt');
        
        try {
            // First copy the files
            await copy({
                source: { key: oldKey, accessLevel: 'private' },
                destination: { key: newKey, accessLevel: 'private' }
            });
    
            try {
                await copy({
                    source: { key: oldSummaryKey, accessLevel: 'private' },
                    destination: { key: newSummaryKey, accessLevel: 'private' }
                });
            } catch (error) {
                console.warn(`Summary file copy issue: ${error.message}`);
            }
    
            // Update local state first
            const updateFileList = (list) => list.map(file => file === oldKey ? newKey : file);
            const updatedPdfList = updateFileList(pdfList);
            const updatedTxtListAI = updateFileList(txtListAI);
            const updatedTxtListInput = updateFileList(txtListInput);
    
            // Update cache with new file information before deleting old file
            const currentCache = cacheService.getFromCache();
            if (currentCache) {
                const updatedCache = {
                    ...currentCache,
                    pdfList: updateFileList(currentCache.pdfList || []),
                    fileDetails: {
                        ...currentCache.fileDetails,
                        [newKey]: currentCache.fileDetails[oldKey]
                    },
                    filePreviews: {
                        ...currentCache.filePreviews,
                        [newKey]: currentCache.filePreviews[oldKey]
                    },
                    fileTypeMap: {
                        ...currentCache.fileTypeMap,
                        [newKey]: currentCache.fileTypeMap[oldKey]
                    }
                };
                // Remove old key entries
                delete updatedCache.fileDetails[oldKey];
                delete updatedCache.filePreviews[oldKey];
                delete updatedCache.fileTypeMap[oldKey];
                
                cacheService.saveToCache(updatedCache);
            }
    
            // Update state
            setPdfList(updatedPdfList);
            setTxtListAI(updatedTxtListAI);
            setTxtListInput(updatedTxtListInput);
            
            // Now delete the old files
            await remove({ key: oldKey, options: { accessLevel: 'private' } });
            try {
                await remove({ key: oldSummaryKey, options: { accessLevel: 'private' } });
            } catch (error) {
                console.warn(`Summary file deletion issue: ${error.message}`);
            }
    
            setEditMode(null);
            setShowRenameModal(false);
            //toast.success('File renamed successfully');
    
            // Fetch fresh data after a short delay
            await new Promise(resolve => setTimeout(resolve, 500));
            await fetchFilesList();
            
        } catch (error) {
            console.error(`Error renaming file: ${error.message}`);
            toast.error('Error renaming file');
            // If there's an error, force refresh the file list
            await fetchFilesList();
        }
    };

    const initiateRename = (fileKey) => {
        setEditMode(fileKey);
        const { namePart } = parseFileKey(fileKey);
        setNewFileName(namePart.replace(/_/g, ' '));
    };

    const confirmRename = async (fileKey, newName) => {
        await handleRename(fileKey, newName.trim());
        setEditMode(null);
        setNewFileName('');
        fetchFilesList();
        toast.success('File renamed successfully');
    };

    const handleDeleteClick = (fileName, event) => {
        event.stopPropagation();
        setDeleteConfirmation({ ...deleteConfirmation, [fileName]: true });
        const timer = setTimeout(() => {
            setDeleteConfirmation({ ...deleteConfirmation, [fileName]: false });
        }, 2500);
    };

    const handleCancelDelete = (fileName, event) => {
        event.stopPropagation();
        setDeleteConfirmation({ ...deleteConfirmation, [fileName]: false });
    };

    /*const handleConfirmDelete = async (fileName) => {
        try {
            await remove({ key: fileName, options: { accessLevel: 'private' } });
            setPdfList(pdfList.filter(file => file !== fileName));
            setTxtListAI(txtListAI.filter(file => file !== fileName));
            setTxtListInput(txtListInput.filter(file => file !== fileName));
            setShowDeleteModal(false);
            toast.success('File deleted successfully');
        } catch (error) {
            console.error('Error deleting file:', error);
        }
    };*/

    const handleConfirmDelete = async (fileName) => {
        try {
            await remove({ key: fileName, options: { accessLevel: 'private' } });
            setPdfList(pdfList.filter(file => file !== fileName));
            setTxtListAI(txtListAI.filter(file => file !== fileName));
            setTxtListInput(txtListInput.filter(file => file !== fileName));
            
            // Clear cache when a file is deleted
            cacheService.clearCache();
            
            setShowDeleteModal(false);
            toast.success('File deleted successfully');
        } catch (error) {
            console.error('Error deleting file:', error);
        }
    };



    const parseFileKey = (fileKey) => {
        let fileType = '';
        let uuid = null;
        let cleanName = '';

        if (fileKey.endsWith('.pdf')) {
            fileType = '.pdf';
            uuid = fileKey.substring(0, 36);
            cleanName = fileKey.substring(37);
        } else if (fileKey.includes('_ai_magic.txt')) {
            fileType = '_ai_magic.txt';
            cleanName = fileKey.replace(/^(AI_Magic\/|TextInput\/)/, '');
            uuid = cleanName.substring(0, 36);
            cleanName = cleanName.substring(37);
        } else if (fileKey.includes('_text_input.txt')) {
            fileType = '_text_input.txt';
            cleanName = fileKey.replace(/^(AI_Magic\/|TextInput\/)/, '');
            uuid = cleanName.substring(0, 36);
            cleanName = cleanName.substring(37);
        }

        let namePart = cleanName.replace(new RegExp(`${fileType}$`), '');

        return { uuid, namePart, fileType };
    };

    const fetchFilePreview = async (fileKey) => {
        try {
            const downloadResult = await downloadData({
                key: fileKey,
                options: {
                    accessLevel: 'private'
                }
            }).result;
            const fileBlob = downloadResult.body;
            if (fileKey.endsWith('.pdf')) {
                const arrayBuffer = await fileBlob.arrayBuffer();
                const uint8Array = new Uint8Array(arrayBuffer);
                const pdf = await getDocument({ data: uint8Array }).promise;
                const firstPage = await pdf.getPage(1);
                const content = await firstPage.getTextContent();
                const text = content.items.map(item => item.str).join(' ');
                return text.substring(0, 100);
            } else if (fileKey.endsWith('.txt')) {
                return await fileBlob.text().then(text => text.substring(0, 100));
            } else {
                return 'Unsupported file format';
            }
        } catch (error) {
            return 'Preview not available';
        }
    };

    const pricingRedirect = (event, pricingState = 'monthly') => {
        if (event) {
          event.preventDefault();
          event.stopPropagation();
        }
        window.open(`/pricing?state=${pricingState}`, '_blank');
      }

    const chartTypeImages = {
        mindmap: "/icons8-brain-96.png",
        flowchart: "/icons8-flowchart-96.png",
        gantt: "/icons8-gantt-chart-96.png",
        reactflowDiagram: "/icons8-diagram-100.png"
    };

    return (
        <div className="tile-gallery-container">
            {/*
            {(!timeLeft.hasSaleStarted && location.pathname !== '/login' && location.pathname !== '/pricing') && !isPaidUser && (
                <div style={{ width: "100%", marginLeft: '-20px', position: 'relative' }} className="saleBanner">
                    <div className="countdown">
                        <>
                            <div className="saleMessage">We are having a flash sale! 🎉</div>
                            <div>
                                Ends in &nbsp; 
                                <span className="timeBox">{timeLeft.days}</span> days
                                <span className="timeBox">{timeLeft.hours} </span> hours
                                <span className="timeBox">{timeLeft.minutes}</span> minutes
                                <span className="timeBox">{timeLeft.seconds}</span> seconds
                            </div>
                            <div className='discountMessage'>
                                <span>First 3 months <strong>ONLY $1/month</strong> - Limited time only!</span>
                            </div>
                            <div className='discountButton'>
                                <Button onClick={pricingRedirect} style={{ background: 'linear-gradient(180deg, rgb(213, 198, 241) 0%, rgb(246, 174, 177) 100%)', color: '#002b31' }}> Act Fast!</Button>
                            </div>
                        </>
                    </div>
                </div>
            )}
            * */}


{!isPaidUser && !isFirstMindMap && (/*
    <div style={{
        background: 'linear-gradient(45deg, #bfd5e3, #80c0e8, #9ee7cf)',
        padding: '20px',
        borderRadius: '10px',
        margin: 'auto',
        textAlign: 'center',
        color: '#002b31',
        width: '60%',
        position: 'relative',
        backgroundSize: '200% 200%',
        animation: 'gradientMove 5s ease infinite'
    }}>
        <div style={{
            position: 'relative',
            top: '-35px',
            margin: 'auto',
            backgroundColor: '#ff4d4d',
            color: '#ffffff',
            padding: '5px 10px',
            borderRadius: '35px',
            fontWeight: 'bold',
            width: '100px',
            fontSize: '14px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        }}>
            32% OFF
        </div>
        
        <p style={{ fontSize: '18px' }}>
            <p style={{ margin: '0 0 10px 0', fontSize:'34px' }}><strong>Save $45</strong> with annual plan</p>
            <div style={{ textAlign: 'center', position: 'relative', display: 'inline-block', fontSize: '20px', fontWeight: '200' }}>
                <span style={{ position: 'relative', zIndex: 1, fontSize: '12px', textAlign: 'center' }}>
                    <span style={{ fontSize: '20px' }}>$</span> <span style={{ fontSize: '2.5rem', fontWeight: '700' }}>{("12")}</span><span style={{ fontSize: '12px' }}>/month</span>
                </span>
                <span style={{
                    position: 'absolute',
                    top: '60%',
                    left: 0,
                    width: '100%',
                    borderTop: '5px solid red',
                    zIndex: 10,
                    transform: 'translateY(-50%)'
                }}></span>
            </div>
            &nbsp;
            <div style={{ display: 'inline-block', fontSize: '20px', fontWeight: '200' }}>
                $<span style={{ fontSize: '2.5rem', fontWeight: '700' }}>{("8")}</span><span style={{ fontSize: '0.8rem', fontWeight: '700' }}>{(".33")}</span><span style={{ fontSize: '12px' }}>/month</span>
            </div>
        </p>
        <ul style={{ listStyleType: 'none', padding: '0', margin: '0px 0px 20px 200px', fontSize: '16px', textAlign: 'left' }}>
            <span style={{ marginRight: '10px' }}><img src="check-circle.svg" style={{ marginRight: '10px' }} alt="Check mark" /></span>Upload any PDF<br></br>
            <span style={{ marginRight: '10px' }}><img src="check-circle.svg" style={{ marginRight: '10px' }} alt="Check mark" /></span>Chat with PDF<br></br>
            <span style={{ marginRight: '10px' }}><img src="check-circle.svg" style={{ marginRight: '10px' }} alt="Check mark" /></span>250 credits/month<br></br>
            <span style={{ marginRight: '10px' }}><img src="check-circle.svg" style={{ marginRight: '10px' }} alt="Check mark" /></span>Edit mind maps and add notes<br></br>
        </ul>
        <Button onClick={(e) => pricingRedirect(e, 'switchToAnnual')} style={{ background: '#002b31', color: '#ffffff', padding: '10px 20px', borderRadius: '20px' }}>
            Get now!
        </Button>
    </div>
*/
<RotatingSaleBanner />
)}
            <div className="tile-title">My Mind Maps:</div>
            <div className="tile-gallery">
                <div className="tile new-tile" onClick={onNewTileClick}>
                    <FaFile size="66px" />
                    New
                </div>
                {loading && (
                    <div className="tile loading-tile">
                        <FaSpinner size="30px" className="fa-spin" />
                        Loading...
                    </div>
                )}
                {[...pdfList, ...txtListAI, ...txtListInput].map(file => (
                    <div
                        className={`tile ${selectedFile === file ? 'selected' : ''}`}
                        key={file}
                        onClick={() => !editMode && handleFileClick(file)}
                    >
                        {editMode === file ? (
                            <>
                                <input value={newFileName} onChange={(e) => setNewFileName(e.target.value)} />
                                <FaSave onClick={(e) => { e.stopPropagation(); confirmRename(file, newFileName); }} />
                                <FaTimes onClick={(e) => { e.stopPropagation(); setEditMode(null); }} />
                            </>
                        ) : (
                            <>
                                <div className="file-preview">
                                    {filePreviews[file] || 'Loading preview...'}
                                </div>
                                <div className='file-tile-and-icon'>
                                <span className="file-name-tile">{FormatFileName(file)}</span>
                                <span className="chart-type">
                                    {fileTypeMap[file] && (
                                        <img 
                                            src={chartTypeImages[fileTypeMap[file]] || "/icons8-default-96.png"} 
                                            alt={chartTypeImages[fileTypeMap[file]] ? `Icon for ${fileTypeMap[file]}` : "Default icon"}
                                            style={{ width: "20px", height: "20px", marginRight: "5px" }}
                                        />
                                    )}
                                </span>
                                </div>
                                <span className="last-modified">Last Modified: {fileDetails[file]?.lastModified}</span>
                                <div className="file-actions">
                                    <FaEllipsisV onClick={(e) => { e.stopPropagation(); handleOpenOptionsMenu(file, e); }} />
                                </div>
                            </>
                        )}
                    </div>
                ))}
            </div>
            {showOptionsMenu && (
                <OptionsMenuModal
                    isOpen={showOptionsMenu}
                    onClose={() => setShowOptionsMenu(false)}
                    onRename={handleRenameOptionClick}
                    onDelete={() => handleDeleteOptionClick(currentFileForOptions)}
                    onEdit={() => handleEditOptionClick(currentFile)}
                    style={{ top: `${menuPosition.top}px`, left: `${menuPosition.left}px`, position: 'fixed' }}
                />
            )}
            {showRenameModal && (
                <RenameMindmapModal
                    isOpen={showRenameModal}
                    onClose={handleCloseRenameModal}
                    currentFileKey={currentFile}
                    fileName={currentFileForOptions}
                    onRename={confirmRename}
                />
            )}
            {showDeleteModal && (
                <Modal
                    isOpen={showDeleteModal}
                    onClose={() => setShowDeleteModal(false)}
                    onConfirm={() => handleConfirmDelete(currentFile)}
                    fileName={currentFileForOptions}
                />
            )}
            <Toaster position="top-center" />
        </div>
    );
};

export default TileGallery;
