import React from 'react';
import { Sparkles, Gift } from 'lucide-react';
import { Box, Paper } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';

// Keyframe animations
const bounce = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
`;

const pulse = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
`;

// Styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  maxWidth: '28rem',
  margin: '0 auto',
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1]
}));

const IconContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative'
});

const StyledGift = styled(Gift)(({ theme }) => ({
  width: 32,
  height: 32,
  color: theme.palette.secondary.light,
  animation: `${bounce} 2s infinite ease-in-out`
}));

const StyledSparkles = styled(Sparkles)(({ theme }) => ({
  width: 24,
  height: 24,
  color: '#FFD700',
  position: 'absolute',
  right: -16,
  top: -8,
  animation: `${pulse} 1.5s infinite ease-in-out`
}));

const LoadingBar = styled(Box)(({ width = '75%' }) => ({
  height: 16,
  backgroundColor: '#f0f0f0',
  borderRadius: 8,
  width,
  margin: '0 auto',
  animation: `${pulse} 1.5s infinite ease-in-out`
}));

const DotContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  justifyContent: 'center',
  marginTop: 16
});

const LoadingDot = styled(Box)(({ delay = 0 }) => ({
  width: 8,
  height: 8,
  backgroundColor: '#edd1d3',
  borderRadius: '50%',
  animation: `${bounce} 1s infinite ease-in-out`,
  animationDelay: `${delay}ms`
}));

const LoadingOffer = () => {
  return (
    <StyledPaper elevation={0}>
      <Box display="flex" flexDirection="column" gap={3} alignItems="center">
        <IconContainer>
          <StyledGift />
          <StyledSparkles />
        </IconContainer>
        
        <Box width="100%" display="flex" flexDirection="column" gap={2}>
          <LoadingBar />
          <LoadingBar width="50%" />
        </Box>

        <DotContainer>
          <LoadingDot />
          <LoadingDot delay={150} />
          <LoadingDot delay={300} />
        </DotContainer>
      </Box>
    </StyledPaper>
  );
};

export default LoadingOffer;